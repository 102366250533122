var barChart = require('../../graphs/classes/BarChart.js');

alert('index');

function updateDatePickers(ref, from, to) {
    $(ref + 'From').datepicker('update', moment(from.date).format('YYYY/MM/D'));
    $(ref + 'To').datepicker('update', moment(to.date).format('YYYY/MM/D'));
}

var userIssueCreatedChart = new barChart({
    canvas: document.getElementById("userCreatedIssuesChart"),
    url: '/admin/graphs/user-created-issues.json',
    config: {
        options: {
            legend:false
        }
    },
    updateCallback(response, graph, root) {
        var plottable = $.map(response.datasets, function(value, index) {
          return [value];
        });
        var z = 0;
        plottable.forEach((element, index) => {
            var data = $.map(element.data, function(value, index) {
                return [value];
            });
            plottable[index].data = data;
            z++;
        });

        graph.data.labels = response.labels;
        graph.data.datasets = plottable;

        updateDatePickers('#uc', response.from, response.to);
    }
});

userIssueCreatedChart.init();


$('.userIssuesCreatedDatepicker').datepicker().on('changeDate', function (ev) {
    var fromElem = $('#ucFrom'),
        toElem = $('#ucTo'),
        from = moment(fromElem.val()),
        to = moment(toElem.val());

    userIssueCreatedChart.refresh({
        from: from.format('YYYY-MM-D'),
        to: to.format('YYYY-MM-D')
    })
});